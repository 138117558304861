var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Model',_vm._g(_vm._b({staticClass:"modal-gather-coupon",scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('table',{staticClass:"g-table normal modal center g-bs-bb"},[_c('thead',[_c('th',{staticClass:"coupon-id text-c"},[_vm._v("优惠券")]),_c('th',{staticClass:"coupon-valid-period"},[_vm._v("有效期")]),_c('th',{staticClass:"coupon-quantity"},[_vm._v("领券量")]),_c('th',{staticClass:"coupon-gather"},[_vm._v("传播渠道")]),_c('th',{staticClass:"coupon-operate",style:({
            'margin-right': _vm.list.length > 5 ? _vm.scrollWidth + 'px' : ''
          })},[_vm._v(" 操作 ")])]),_c('tbody',_vm._l((_vm.list),function(item,index){return _c('tr',{key:item.activity_id},[_c('td',{staticClass:"coupon-id",attrs:{"id":'coupon-id' + index,"data-clipboard-text":item.activity_id},on:{"click":function($event){return _vm.copyText('#coupon-id' + index)}}},[(item.fullReduction)?_c('span',{staticClass:"g-bgc-orange g-color-white box-radius full-reduction"},[_vm._v(" "+_vm._s(item.fullReduction)+" ")]):_vm._e(),_c('section',{staticClass:"coupon-id-content g-tip-wrap"},[_c('p',{staticClass:"g-color-primary",attrs:{"title":item.couponUrl}},[_vm._v(" "+_vm._s(_vm._f("couponId")(item.activity_id))+" ")])])]),_c('td',{staticClass:"coupon-valid-period"},[_vm._v(" "+_vm._s(_vm._f("monthDay")(item.couponStartDate))+"-"+_vm._s(_vm._f("monthDay")(item.couponEndDate))+" ")]),_c('td',{staticClass:"coupon-gather"},[_c('span',{staticClass:"g-color-orange"},[_vm._v(_vm._s(_vm._f("none")(_vm._f("tenThousand")(item.couponReceiveCount))))]),_vm._v(" / "),_c('span',[_vm._v(_vm._s(_vm._f("none")(_vm._f("tenThousand")(item.couponTotalCount))))])]),_c('td',{staticClass:"coupon-gather"},[_c('ul',{staticClass:"channels"},[_vm._l((item.channels),function(itemChannel){return [(itemChannel.quantity > 0)?_c('li',{key:itemChannel.type,staticClass:"g-cursor-pointer",on:{"click":function($event){return _vm.channel(itemChannel, {
                      activity_id: item.activity_id,
                      limit: itemChannel.quantity
                    })}}},[_c('section',[_c('img',{class:{
                        'late-1': itemChannel.type !== 'hdk',
                        'late-3': itemChannel.type === 'sina'
                      },attrs:{"src":_vm.channelImg[itemChannel.type],"alt":""}})]),_c('span',{staticClass:"g-color-primary",class:{ 'g-text-underline': itemChannel.quantity > 0 }},[_vm._v(_vm._s(_vm._f("none")(_vm._f("tenThousandW")(itemChannel.quantity))))])]):_vm._e()]})],2)]),_c('td',{staticClass:"coupon-operate"},[_c('a',{staticClass:"g-bgc-orange g-color-white",attrs:{"href":item.couponUrl,"target":"_blank"}},[_vm._v("领取")]),_c('button',{staticClass:"g-bgc-primary g-color-white",attrs:{"id":'coupon-url' + index,"data-clipboard-text":item.couponUrl},on:{"click":function($event){return _vm.copyText('#coupon-url' + index)}}},[_vm._v(" 复制链接 ")])])])}),0)])]},proxy:true}])},'Model',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }