import { render, staticRenderFns } from "./ShopIcons.vue?vue&type=template&id=6971be37&scoped=true&"
import script from "./ShopIcons.vue?vue&type=script&lang=ts&"
export * from "./ShopIcons.vue?vue&type=script&lang=ts&"
import style0 from "./ShopIcons.vue?vue&type=style&index=0&id=6971be37&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6971be37",
  null
  
)

export default component.exports