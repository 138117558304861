import { Vue, Component } from 'vue-property-decorator'

@Component
export default class SearchTabImg extends Vue {
  searchTabImgActive = 0

  created() {
    this['$bus'].$on('changeSearchTabImg', (active: number) => {
      this.initBySearchTabImg(active)
    })
  }

  beforeDestroy() {
    // TODO: 查看为什么卸载前关闭事件会导致路由改变时无法触发事件监听
    // this['$bus'].$off('changeSearchTabImg', 0)
  }

  initBySearchTabImg(active: number) {
    this.searchTabImgActive = active

    this['afterInitBySearchTabImg'] && this['afterInitBySearchTabImg'](active)
  }
}
