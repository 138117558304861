import { Vue, Component } from 'vue-property-decorator'
import { getShopScore } from '@/api/common'
import { getProductShopScores } from '@bizUtils/product'

@Component
export default class Shop extends Vue {
  async getShopScore(product: {}) {
    if (product['shopScores'].length > 0) {
      return
    }

    const data = await getShopScore(
      {
        seller_id: product['seller_id']
      },
      {
        loading: {
          mountDom: this.$refs.shopScore
        }
      }
    )

    product['shopScores'] = getProductShopScores(data)
  }
}
