import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Channel extends Vue {
  channel(item: {}, other?: {}): void {
    if (item['quantity'] > 0 && item['type'] !== 'coupon') {
      this.$emit('channel', item, other || this['param'])
    } else if (item['type'] === 'coupon' && item['quantity'] > 0) {
      this.$emit(item['type'])
    }
  }
}
