import { Vue, Component } from 'vue-property-decorator'
@Component
export default class Copy extends Vue {
  copyContent = '复制文案'
  clearTimeout: any = null

  coptyMouseout() {
    this.copyContent = '复制文案'
    clearTimeout(this.clearTimeout)
  }

  copyCallback() {
    this.copyContent = '复制成功'
    this.clearTimeout = setTimeout(() => {
      this.copyContent = '复制文案'
    }, 2000)
  }
}
